import React from "react";
import {Container,  Box, Typography, Paper} from '@mui/material';
import Header from "./Header";
import Contacts from "./Contacts";
import TopGames from "./TopGames";

export default function About() {
  return (
    <>
      <Header />
      <Container>
        <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
            <Paper elevation={3} sx={{ padding: 5, backgroundColor: "primary.main500" }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    About Betmagicplace
                </Typography>
                <Typography variant="body1" paragraph>
                    Betmagicplace is dedicated to providing top-quality free social entertainment games. We specialize in crafting engaging and immersive experiences without any financial risks for our players.
                </Typography>
                <Typography variant="body1" paragraph>
                    Our platform is built on the principles of fair play and transparency. All games featured on Betmagicplace use certified random number generators to ensure fair outcomes, emphasizing our commitment to trust and integrity.
                </Typography>
                <Typography variant="body1" paragraph>
                    We offer a wide variety of themed games to cater to diverse tastes and interests, from adventurous explorations to classic casino styles, all available across multiple devices.
                </Typography>
                <Typography variant="body1" paragraph>
                    Our mission is to provide a safe and entertaining environment where players can enjoy gaming without any commitments or financial implications. Betmagicplace is a haven for those who seek to enjoy the thrill of gaming in a purely social and non-competitive context.
                </Typography>
                <Typography variant="body1" paragraph>
                    Established in 2023, Betmagicplace has quickly become a leading provider in the free social gaming space, continuously expanding our game library and improving user experiences.
                </Typography>
            </Paper>
        </Box>

        <TopGames/>

        <Contacts />
      </Container>
    </>
  );
}

// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <Box sx={{ backgroundColor: "primary.main500" }}>
      <Container maxWidth="lg">
        
          {/* Image Block */}
          {/* <img
          src={ctaBg}
          alt="CTA"
          style={{ maxWidth: '400px', width: "100%", height: 'auto' }}
        /> */}
          {/* Text Block */}
          <Grow in={true} timeout={1200} appear={true}>
            <Box mt={3}>
              <Typography variant="h2" textAlign="center" gutterBottom>
              About Us
              </Typography>
              <Typography variant="h4" paragraph>
              At Betmagicplace, we believe in creating a thrilling gaming atmosphere that's both safe and exhilarating! Experience the best of virtual pokies without any risk. Play, connect, and enjoy our extensive collection of themed games designed just for fun!
              </Typography>

              <Typography variant="h4" paragraph>
              Your Adventure Awaits at Betmagicplace! 🌟 Whether you're here to play casually or to experience the thrill of pokies in a new way, we're delighted to bring you a world of entertainment without the risk. Visit us daily for fresh fun, exciting games, and magical moments. Let the reels spin and the magic begin!
              </Typography>

            

              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/about"
              >
                Know More
              </Button>
            </Box>
          </Grow>
      </Container>
    </Box>
  );
}

export default CTA;

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: "#32012F",    // Deep plum as the main dark shade
      main: "#32012F",    // Using the main plum shade as primary
      main600: "#4A0239", // Darker variant of plum for subtle depth
      main500: "#5B0344", // Slightly lighter plum for intermediate use
      main400: "#6D0450", // Even lighter plum, ideal for accents
      main300: "#7E055B", // Lighter shade of plum, more vivid
      main200: "#8F0667", // Soft plum, moving towards a softer tone
      main100: "#A00772", // Near pastel plum, lighter and softer
      main50: "#B2087E",  // Lightest pastel plum, very soft
  },
  text: {
      primary: "#E2DFD0", // Light beige for high contrast with dark backgrounds
  },
  secondary: {
      main: "#F97300",   // Vibrant orange as the main accent color
      secondary: "#E2DFD0", // Light beige used for secondary elements and text
  },  
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Poetsen One, sans-serif',
    color: "white",
    h1: {
      xs: {fontSize: "1rem"}, 
      md: {fontSize: "5rem"},
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});


export default theme;
